import styled from 'styled-components';

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #f0f2f5;
  padding-right: 0px;
  overflow-y: auto; 
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  
  h1 {
    font-size: 24px;
    color: #333333;
    margin: 0;
  }
`;

export const FormContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50%;
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  color: #333333;
`;

export const UploadButton = styled.button`
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const SaveButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #28a745;
  color: #ffffff;
  cursor: pointer;
  align-self: flex-start;

  &:hover {
    background-color: #218838;
  }
`;
