import React, { useState, useEffect } from 'react';
import { useApiClient } from '../../hooks/useApiClient';  // Import the custom hook
import { 
  StepContainer, 
  Column, 
  ColumnTitle, 
  FieldContainer, 
  Label, 
  Description, 
  Input, 
  Switch, 
  ImageDropArea, 
  ImagePreview, 
  SliderContainer, 
  SliderInput, 
  SliderValue,
  ErrorText,
  RecapColumn,
  RecapCard,
  RecapItem,
  RecapTitle,
  RowContainer,
  SeparatorColumn,
  Button // Import Button for "Next"
} from '../../styles/creation_steps_styles/StepTwoStyles';

const StepTwo = ({ projectData, setProjectData, nextStep }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [errors, setErrors] = useState({});
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [propertyPlanName, setPropertyPlanName] = useState('');
  const apiClient = useApiClient();  // Use the custom hook

  const handleInputChange = (field, value) => {
    if (field === 'propertyValue') {
      // Ensure the value is a number
      const numericValue = value.replace(/[^0-9]/g, '');
      setProjectData({ ...projectData, [field]: numericValue });
    } else {
      setProjectData({ ...projectData, [field]: value });
    }

    // Update the file name state if a property plan is selected
    if (field === 'propertyPlan' && value) {
      setPropertyPlanName(value.name);
    }

    validateFields(); // Revalidate on every input change
  };

  const handleBlur = (field, value) => {
    if (field === 'propertyValue' && value) {
      // Format the value as USD on blur
      const formattedValue = Number(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      setProjectData({ ...projectData, [field]: formattedValue });
    }
  };

  const handleImageChange = (file) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      handleInputChange('imageCover', file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleImageChange(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    handleImageChange(file);
  };

  const handleSliderChange = (field, value) => {
    if (field === 'configurations') {
      value = Math.max(10, Math.min(50, value));
    } else if (field === 'streamingMinutes') {
      value = Math.max(60, Math.min(600, value));
    }
    handleInputChange(field, value);
  };

  const validateFields = () => {
    const newErrors = {};

    if (!projectData.propertyName) newErrors.propertyName = 'This field is required';
    if (!projectData.imageCover) newErrors.imageCover = 'This field is required';
    if (!projectData.propertyValue || isNaN(Number(projectData.propertyValue)) || Number(projectData.propertyValue) <= 0) {
      newErrors.propertyValue = 'This field is required and must be a number greater than 0';
    }
    if (projectData.isFurnished === undefined) newErrors.isFurnished = 'This field is required';
    if (!projectData.propertyPlan) newErrors.propertyPlan = 'This field is required';
    if (!projectData.configurations) newErrors.configurations = 'This field is required';
    if (!projectData.streamingMinutes) newErrors.streamingMinutes = 'This field is required';

    setErrors(newErrors);
    setIsNextDisabled(Object.keys(newErrors).length > 0);
  };

  useEffect(() => {
    validateFields();

    // Set image preview if there's already an image in projectData
    if (projectData.imageCover && !imagePreview) {
      setImagePreview(URL.createObjectURL(projectData.imageCover));
    }

    // If there's a property plan, set the file name
    if (projectData.propertyPlan && !propertyPlanName) {
      setPropertyPlanName(projectData.propertyPlan.name);
    }
  }, [projectData, imagePreview, propertyPlanName]);

  const handleNext = async () => {
    // Prepare form data
    const formData = new FormData();
    formData.append('project_name', projectData.propertyName);
    formData.append('project_address', projectData.propertyAddress);
    formData.append('listing_link', projectData.listingLink);
    formData.append('price', projectData.propertyValue);
    formData.append('house_plan_file', projectData.propertyPlan);
    formData.append('cover_image', projectData.imageCover);
    formData.append('active', 'None'); // You can change this if there's a specific value to be used
    formData.append('max_streaming_minutes_month', projectData.streamingMinutes);
    formData.append('max_configurations', projectData.configurations);
    formData.append('is_furnished', projectData.isFurnished);
    console.log("started sending");
    try {
      const response = await apiClient.post('/projects/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        console.log('Project created successfully');
        // Proceed to the next step
        nextStep();
      } else {
        console.error('Failed to create project:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  return (
    <StepContainer>
      <Column>
        <ColumnTitle>Property Data</ColumnTitle>
        <FieldContainer>
          <Label>Upload an Image Cover for this Property</Label>
          <Description>Drag and drop an image type file</Description>
          <ImageDropArea 
            onClick={() => document.getElementById('imageUpload').click()}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            dragging={dragging}
          >
            {imagePreview ? (
              <ImagePreview src={imagePreview} alt="Image Preview" />
            ) : (
              <span>+</span>
            )}
            <input 
              id="imageUpload" 
              type="file" 
              accept="image/*" 
              onChange={handleFileInputChange} 
              style={{ display: 'none' }}
            />
          </ImageDropArea>
          {errors.imageCover && <ErrorText>{errors.imageCover}</ErrorText>}
        </FieldContainer>

        <RowContainer>
        <FieldContainer>
          <Label>Property Name</Label>
          <Input 
            type="text" 
            value={projectData.propertyName || ''} 
            onChange={(e) => handleInputChange('propertyName', e.target.value)} 
          />
          {errors.propertyName && <ErrorText>{errors.propertyName}</ErrorText>}
        </FieldContainer>

        <FieldContainer>
          <Label>Property Address</Label>
          <Input 
            type="text" 
            value={projectData.propertyAddress || ''} 
            onChange={(e) => handleInputChange('propertyAddress', e.target.value)} 
          />
        </FieldContainer>
      </RowContainer>

      <RowContainer>
        <FieldContainer>
          <Label>Property Value ($ USD)</Label>
          <Input 
            type="text" 
            value={projectData.propertyValue || ''} 
            onChange={(e) => handleInputChange('propertyValue', e.target.value)} 
            onBlur={(e) => handleBlur('propertyValue', e.target.value)}
          />
        </FieldContainer>

        <FieldContainer>
          <Label>Listing Link</Label>
          <Input 
            type="text" 
            value={projectData.listingLink || ''} 
            onChange={(e) => handleInputChange('listingLink', e.target.value)} 
          />
        </FieldContainer>
      </RowContainer>
      </Column>
      <SeparatorColumn></SeparatorColumn>
      <Column>
        <ColumnTitle>Virtual Configuration</ColumnTitle>
        <FieldContainer>
          <Label>Shall the property be furnished by us?</Label>
          <Description>Whether you want the virtual property to be furnished by us or you want it empty with only the walls (leads to additional initial set up costs)</Description>
          <Switch 
            checked={projectData.isFurnished || false} 
            onChange={(e) => handleInputChange('isFurnished', e.target.checked)} 
          />
          {errors.isFurnished && <ErrorText>{errors.isFurnished}</ErrorText>}
        </FieldContainer>

        <FieldContainer>
            <Label>Upload the Property Plan</Label>
            <Description>Drag and drop a .dwg or .pdf file</Description>
            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <Input 
                    type="file" 
                    accept=".dwg,.pdf" 
                    onChange={(e) => handleInputChange('propertyPlan', e.target.files[0])} 
                    style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
                />
                <div 
                    style={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '5px',
                        padding: '10px',
                        backgroundColor: '#ffffff',
                        color: '#333333',
                        cursor: 'pointer'
                    }}
                >
                    {propertyPlanName || 'No file selected'}
                </div>
            </div>
            {errors.propertyPlan && <ErrorText>{errors.propertyPlan}</ErrorText>}
        </FieldContainer>

        <Button onClick={handleNext}>Next</Button>
      </Column>
    </StepContainer>
  );
};

export default StepTwo;
