  import styled from 'styled-components';

  export const DashboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f0f2f5;
    padding-right: 0px;
    overflow-y: auto; /* Makes the dashboard scrollable */
    height: 100vh; /* Ensure it takes the full height of the viewport */
  `;

  export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    
    h1 {
      font-size: 24px;
      color: #333333;
      margin: 0;
    }
  `;

  export const SearchContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
  `;

  export const SearchInput = styled.input`
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    color: #333333;
    max-width: 250px; /* Adjust max-width as needed */
    width: 100%; /* Allow the input to take the available width */
    margin: 0px 40px 0px 20px
  `;


  export const ProjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  overflow-y: auto; /* Allow scrolling within the projects container */
`;

  export const CreateButton = styled.button`
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  `;
