import React, { useState, useEffect } from 'react';
import { Container, MainContent, ContentWrapper, ProjectDetailsWrapper, DashboardWrapper, Card, Title, Button } from '../styles/HomeStyles';
import { useNavigate, Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import ProjectDetails from './ProjectDetails';
import CreateProject from './CreateProject';
import Account from './Account';  
import Help from './Help';  
import EditProject from './EditProject';  // Import the new component
import PixelStreamingComponent from './PixelStreamingComponent';

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [activeSection, setActiveSection] = useState('projects');
  const [isEditing, setIsEditing] = useState(false);  // New state to track if we are in edit mode
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleEditProject = () => {
    setIsEditing(true);
    setActiveSection('editProject');
  };

  const handleSaveProject = (updatedProjectData) => {
    // Save the updated project data to your backend or state
    setSelectedProject(updatedProjectData);
    setIsEditing(false);
    setActiveSection('projects');
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setActiveSection('projects');
  };

  const handleDeleteProject = (projectId) => {
    // Implement your delete logic here, e.g., removing the project from state or making an API call
    console.log(`Project with ID ${projectId} deleted.`);
    
    // Clear the selected project after deletion
    setSelectedProject(null);
    setIsEditing(false);
    setActiveSection('projects');
  };

  const renderSection = () => {
    switch (activeSection) {
      case 'projects':
        return <Dashboard setSelectedProject={setSelectedProject} activeSection={activeSection} setActiveSection={setActiveSection} selectedProject={selectedProject} />;
      case 'createProject':
        return <CreateProject />;
      case 'account':
        return <Account />;
      case 'help':
        return <Help />;
      case 'streaming':
        return <PixelStreamingComponent />;
      case 'editProject':  // New case for edit project
        return (
          <EditProject 
            project={selectedProject} 
            onSave={handleSaveProject} 
            onCancel={handleCancelEdit} 
          />
        );
      default:
        return <Dashboard setSelectedProject={setSelectedProject} activeSection={activeSection} setActiveSection={setActiveSection} />;
    }
  };

  if (!isLoggedIn) {
    return (
      <Container>
        <Card>
          <Title>Welcome to Versy Tech</Title>
          <Link to="/login">
            <Button>Login</Button>
          </Link>

          {/* 
          <Link to="/register" style={{ marginTop: '10px' }}>
            <Button>Register</Button>
          </Link> 
          */}
          
        </Card>
      </Container>
    );
  }

  return (
    <Container>
      <Sidebar 
        setActiveSection={setActiveSection} 
        setIsLoggedIn={setIsLoggedIn} 
        activeSection={activeSection}  // Pass the activeSection as a prop
      />
      <MainContent>
        <ContentWrapper>
          <DashboardWrapper>
            {renderSection()}
          </DashboardWrapper>
          {activeSection === 'projects' && selectedProject && !isEditing && (
            <ProjectDetailsWrapper>
              <ProjectDetails 
                project={selectedProject} 
                onEdit={handleEditProject} 
                onDelete={handleDeleteProject}  // Add the onDelete handler
              />
            </ProjectDetailsWrapper>
          )}
        </ContentWrapper>
      </MainContent>
    </Container>
  );
}

export default Home;
