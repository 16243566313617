import React, { useState, useEffect } from 'react';
import { DashboardContainer, Header, SearchContainer, SearchInput, ProjectsContainer, CreateButton } from '../styles/DashboardStyles';
import ProjectCard from './ProjectCard';
import { useApiClient } from '../hooks/useApiClient';  // Import the custom hook

const Dashboard = ({ setSelectedProject, activeSection, setActiveSection, selectedProject }) => {
  const [searchQuery, setSearchQuery] = useState('');  // State to keep track of the search query
  const [projects, setProjects] = useState([]);  // State to store projects
  const apiClient = useApiClient();  // Use the custom hook

  useEffect(() => {
    // Fetch projects from the backend API
    const fetchProjects = async () => {
      try {
        const response = await apiClient.get('/projects/');
        setProjects(response.data);  // Update the projects state with the data
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();  // Call the fetch function
  }, [apiClient]);  // apiClient is now a dependency

  // Filter projects based on the search query
  const filteredProjects = projects.filter(project => {
    const title = project.project_name ? project.project_name.toLowerCase() : '';
    const location = project.project_address ? project.project_address.toLowerCase() : '';
    const query = searchQuery.toLowerCase();
    
    return title.includes(query) || location.includes(query);
  });

  return (
    <DashboardContainer>
      {activeSection === 'projects' && (
        <Header>
          <h1>Projects Dashboard</h1>
          <SearchContainer>
            <CreateButton onClick={() => setActiveSection('createProject')}>Create Project</CreateButton>
            <SearchInput
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}  // Update search query as the user types
            />
          </SearchContainer>
        </Header>
      )}
      <ProjectsContainer>
        {filteredProjects.map(project => (
          <ProjectCard
            key={project.id}
            project={project}  // Pass the entire project object, including configurations
            isSelected={selectedProject && selectedProject.id === project.id}
            onClick={() => setSelectedProject(project)}  // Set selected project
          />
        ))}
      </ProjectsContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
