import React, { useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';

const PixelStreamingComponent = () => {
  useEffect(() => {
    console.log('PixelStreamingComponent mounted');

    const script = document.createElement('script');
    const projectKey = '26c5c933-1cc7-4625-a236-59abf08a1fcf'; // Hardcoded project key
    script.src = `https://embed.arcanemirage.com/${projectKey}/e`;
    script.onload = () => {
      console.log('Script loaded successfully');
      if (window['initArcanePlayer']) {
        window['initArcanePlayer']();
      } else {
        console.error('initArcanePlayer is not defined');
      }
    };

    const amContainer = document.getElementById('am-container');
    if (amContainer) {
      amContainer.appendChild(script);
    } else {
      console.error('am-container not found in the document');
    }

    window.addEventListener('ArcanePlayerLoaded', () => {
      const player = window['ArcanePlayer'];
      console.log('ArcanePlayer loaded:', player);

      // Setting up event listeners
      player.onReceiveEvent('CustomUIEventResponse', (response) => {
        console.log({ ArcaneResponse: response });
      });

      player.onPlayerEvent('loading', () => {
        console.log('loading');
      });

      player.onPlayerEvent('ready', () => {
        console.log('ready');
      });

      player.onPlayerEvent('afkWarning', () => {
        console.log('afkWarning');
      });

      player.onPlayerEvent('afkWarningDeactivate', () => {
        console.log('afkWarningDeactivate');
      });

      player.onPlayerEvent('afkTimedOut', () => {
        console.log('afkTimedOut');
      });

      // Emitting events to Unreal Engine
      player.emitUIEvent('MyCustomEventWithoutData');
      player.emitUIEvent({
        event: 'MyCustomEventWithData',
        data: { foo: 'bar' },
      });

      // Receiving events from Unreal Engine
      player.onReceiveEvent('event.MyCustomEventWithData', (response) => {
        console.log({ ArcaneResponse: response });
      });

      // Starting the stream programmatically
      player.play();

      // Toggle fullscreen mode
      player.toggleFullscreen();

      // Receive file events
      player.onPlayerEvent('fileProgress', (progress) => {
        console.log('File download progress:', progress);
      });

      player.onPlayerEvent('fileReceived', (data) => {
        const a = document.createElement('a');
        a.setAttribute('href', URL.createObjectURL(data.file));
        a.style.display = 'none';
        a.setAttribute('download', `received_file.${data.extension}`);
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    });

    return () => {
      console.log('Cleaning up PixelStreamingComponent');
      if (amContainer) {
        amContainer.removeChild(script);
      }
      window.removeEventListener('ArcanePlayerLoaded', () => {});
    };
  }, []);

  return (
    <>
      <div
        id="arcane-player"
        style={{
          minWidth: '1920px',
          minHeight: '1080px',
          width: '100%',
          height: '100%',
        }}
        data-project-id="3392" // Hardcoded project ID
        data-project-key="26c5c933-1cc7-4625-a236-59abf08a1fcf" // Hardcoded project key
        data-idle-timeout="200"
        data-capture-mouse="false"
        data-enable-events-passthrough="true"
        data-hide-ui-controls="true"
        data-autoplay="false"
      ></div>
      <button
        onClick={() => {
          const player = window['ArcanePlayer'];
          player.emitUIEvent({
            event: 'MyCustomEventWithData',
            data: { foo: 'bar' },
          });
        }}
      >
        Fire Event
      </button>
    </>
  );
};

// Create the container and mount the component inside it
const App = () => {
  useEffect(() => {
    console.log('App component mounted');

    const amContainer = document.createElement('div');
    amContainer.id = 'am-container';
    amContainer.style.minWidth = '1920px';
    amContainer.style.minHeight = '1080px';
    document.body.appendChild(amContainer);

    ReactDOM.createRoot(amContainer).render(<PixelStreamingComponent />);
  }, []);

  return null; // No need to render anything else in this component
};

export default App;
