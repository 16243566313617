import React, { useEffect } from 'react';

const StepThree = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div>
      <h2>Select Your Pricing Plan</h2>
      <stripe-pricing-table 
        pricing-table-id="prctbl_1PmBFjKz0yq8gQgdvhc5FFTH"
        publishable-key="pk_test_51PmAWnKz0yq8gQgdmcmyUL9PF73J430dFa5jujJJACQLbolOo8EXyrg7lA5dZMC7YJQaqiFjidis54q1X6V7AxrG00BLxLYtq5">
      </stripe-pricing-table>
    </div>
  );
}

export default StepThree;
