import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './components/Register';
import Home from './components/Home';
import Login from './components/Login';
import TermsOfService from './components/TermsOfService';  // Import new component
import PrivacyPolicy from './components/PrivacyPolicy';  // Import new component
import PixelStreamingComponent  from './components/PixelStreamingComponent';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />  {/* New route */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />  {/* New route */}
        <Route path="/streaming" element={<PixelStreamingComponent />} />  {/* New route for PixelStreamingComponent */}
      </Routes>
    </Router>
  );
}

export default App;
