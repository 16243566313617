import React, { useState, useEffect } from 'react';
import { useApiClient } from '../hooks/useApiClient';  // Import the custom hook
import { 
  DetailsContainer, 
  Image, 
  Title, 
  Address, 
  Button, 
  Stats, 
  Dropdown,
  StatItem, 
  BottomButtonContainer, 
  PriceTag, 
  DeleteInput, 
  DeleteButton,
  NewConfigContainer,
  ConfigInput,
  SwitchContainer,
  SwitchLabel,
  Switch,
  ProjectSettingsContainer,
  SettingsTitle,
  DeleteConfigButtonContainer,
  DeleteConfigButton,
  DeleteConfigIcon
} from '../styles/ProjectDetailsStyles';

const ProjectDetails = ({ project, onEdit, onDelete }) => {
  const [selectedConfigurations, setSelectedConfigurations] = useState({}); // Track selected config per project
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showNewConfig, setShowNewConfig] = useState(false);
  const [newConfigName, setNewConfigName] = useState('');
  const [requestedFurniture, setRequestedFurniture] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState('');
  const [showConfigDeleteConfirmation, setShowConfigDeleteConfirmation] = useState(false);
  const apiClient = useApiClient();  // Use the custom hook

  // Reset deletion states when the selected project changes
  useEffect(() => {
    setShowDeleteConfirmation(false);
    setShowConfigDeleteConfirmation(false);
    setConfirmationInput('');
  }, [project]);

  // Update the selected configuration when a project is selected
  useEffect(() => {
    if (project && project.configurations.length > 0) {
      const initialConfig = selectedConfigurations[project.id] || project.configurations[0];
      setSelectedConfigurations(prevState => ({
        ...prevState,
        [project.id]: initialConfig
      }));
    }
  }, [project]);

  const handleConfigChange = (e) => {
    const selectedConfigId = e.target.value;
    if (selectedConfigId === "add-new") {
      setShowNewConfig(true);
    } else {
      const selectedConfig = project.configurations.find(config => config.id === parseInt(selectedConfigId));
      setSelectedConfigurations(prevState => ({
        ...prevState,
        [project.id]: selectedConfig
      }));
      setShowNewConfig(false);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    if (confirmationInput === project.project_name) {
      try {
        const response = await apiClient.delete(`/projects/${project.id}`);

        if (response.status === 200) {
          alert('Project deleted successfully');
          window.location.reload(); 
        } else {
          console.error('Failed to delete project:', response.statusText);
        }
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    } else {
      alert('Project name does not match. Please try again.');
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
    setConfirmationInput('');
  };

  const handleNewConfigConfirm = async () => {
    try {
      const response = await apiClient.post(`/projects/${project.id}/configurations`, {
        name: newConfigName,
        requested_furniture: requestedFurniture
      });

      if (response.status === 201) {
        alert('Configuration created successfully');
        window.location.reload(); 
      } else {
        console.error('Failed to create configuration:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating configuration:', error);
    }
  };

  const handleCancelNewConfig = () => {
    setShowNewConfig(false);
    setNewConfigName('');
    setRequestedFurniture(false);
  };

  const handleDeleteConfigClick = () => {
    setShowConfigDeleteConfirmation(true);
  };

  const handleDeleteConfigConfirm = async () => {
    const selectedConfig = selectedConfigurations[project.id];

    if (confirmationInput === selectedConfig.name) {
      try {
        const response = await apiClient.delete(`/projects/${project.id}/configurations/${selectedConfig.id}`);

        if (response.status === 200) {
          alert('Configuration deleted successfully');
          window.location.reload(); 
        } else {
          console.error('Failed to delete configuration:', response.statusText);
        }
      } catch (error) {
        console.error('Error deleting configuration:', error);
      }
    } else {
      alert('Configuration name does not match. Please try again.');
    }
  };

  const handleCancelConfigDelete = () => {
    setShowConfigDeleteConfirmation(false);
    setConfirmationInput('');
  };

  if (!project) return <DetailsContainer>Select a project to see details</DetailsContainer>;

  const selectedConfig = selectedConfigurations[project.id];

  return (
    <DetailsContainer>
      <div style={{ position: 'relative', width: '100%' }}>
        <Image src={project.cover_image || 'https://via.placeholder.com/200'} alt={project.project_name} />
        <PriceTag>${project.price || '1,000,000'}</PriceTag> 
      </div>
      <Title>{project.project_name}</Title>
      <Address>{project.project_address}</Address>

      {showDeleteConfirmation ? (
        <>
          <p>Type the project name <b>"{project.project_name}"</b> to confirm deletion:</p>
          <DeleteInput 
            type="text" 
            value={confirmationInput} 
            onChange={(e) => setConfirmationInput(e.target.value)} 
            placeholder="Project name"
          />
          <BottomButtonContainer>
            <DeleteButton onClick={handleDeleteConfirm}>Confirm Delete</DeleteButton>
            <Button $cancel onClick={handleCancelDelete}>Cancel</Button>
          </BottomButtonContainer>
        </>
      ) : showNewConfig ? (
        <NewConfigContainer>
          <ConfigInput 
            type="text" 
            value={newConfigName} 
            onChange={(e) => setNewConfigName(e.target.value)} 
            placeholder="Configuration Name" 
          />
          <SwitchContainer>
            <SwitchLabel>Do you want us to furnish this configuration?</SwitchLabel>
            <Switch 
              checked={requestedFurniture} 
              onChange={(e) => setRequestedFurniture(e.target.checked)} 
            />
          </SwitchContainer>
          <BottomButtonContainer>
            <Button onClick={handleNewConfigConfirm}>Create</Button>
            <Button $cancel onClick={handleCancelNewConfig}>Cancel</Button>
          </BottomButtonContainer>
        </NewConfigContainer>
      ) : showConfigDeleteConfirmation ? (
        <>
          <p>Type the configuration name <b>"{selectedConfig?.name}"</b> to confirm deletion:</p>
          <DeleteInput 
            type="text" 
            value={confirmationInput} 
            onChange={(e) => setConfirmationInput(e.target.value)} 
            placeholder="Configuration name"
          />
          <BottomButtonContainer>
            <DeleteButton onClick={handleDeleteConfigConfirm}>Confirm Delete</DeleteButton>
            <Button $cancel onClick={handleCancelConfigDelete}>Cancel</Button>
          </BottomButtonContainer>
        </>
      ) : (
        <>
          <Dropdown value={selectedConfig?.id || ''} onChange={handleConfigChange}>
            <option value="" disabled>Select Configuration</option>
            {project.configurations.map(config => (
              <option key={config.id} value={config.id}>
                {config.name}
              </option>
            ))}
            <option value="add-new">Add new configuration...</option>
          </Dropdown>

          {selectedConfig && (
            <DeleteConfigButtonContainer onClick={handleDeleteConfigClick}>
              <DeleteConfigIcon>🗑️</DeleteConfigIcon>
              <DeleteConfigButton>Delete {selectedConfig.name} configuration</DeleteConfigButton>
            </DeleteConfigButtonContainer>
          )}

          <Stats>
            <StatItem>Configurations: {project.configurations.length}</StatItem>
            <StatItem>HQ Streaming: {project.used_minutes}/{project.max_streaming_minutes_month || 'N/A'}</StatItem>
            <StatItem>Views: {project.views || 0}</StatItem>
          </Stats>

          <ProjectSettingsContainer>
            <SettingsTitle>Project Settings</SettingsTitle>
            <Button onClick={() => onEdit(project)}>Edit</Button> 
            <Button $delete onClick={handleDeleteClick}>Delete</Button>
          </ProjectSettingsContainer>
        </>
      )}
    </DetailsContainer>
  );
};

export default ProjectDetails;
