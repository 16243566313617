import React, { useState } from 'react';
import { CreateProjectContainer, Header, Title, CardsContainer, ProjectCard, StepIndicator, StepIndicatorContainer, Step, Button } from '../styles/CreateProjectStyles';
import StepTwo from './creation_steps_components/StepTwo';
import StepThree from './creation_steps_components/StepThree';

const CreateProject = () => {
  const [step, setStep] = useState(1);
  const [projectData, setProjectData] = useState({
    projectType: '',
    propertyName: '',
    imageCover: null,
    propertyValue: '',
    isFurnished: false,
    propertyPlan: null,
    configurations: 3,
    streamingMinutes: 60,
    // Add other fields as needed
  });

  const isNextDisabled = !projectData.propertyName || !projectData.imageCover || !projectData.propertyValue || !projectData.propertyPlan;

  const handleProjectTypeSelection = (type) => {
    setProjectData({ ...projectData, projectType: type });
    setStep(2);
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <CardsContainer>
            <ProjectCard onClick={() => handleProjectTypeSelection('Upload House Plan')}>
              <img src={`${process.env.PUBLIC_URL}/house_plan_create_image.jpg`} alt="Upload House Plan" />
              <h2>Upload House Plan</h2>
              <p>Create a project starting from a house plan in .dwg or .pdf format. <br></br><br></br>After project submission it could take up to 48 hours for the virtual house to be ready</p>
            </ProjectCard>
            <ProjectCard 
              style={{ backgroundColor: '#f0f0f0', color: '#888', cursor: 'not-allowed' }} 
              onClick={(e) => e.preventDefault()}
            >
              <img 
                src={`${process.env.PUBLIC_URL}/template_create_image.jpg`}
                alt="Template" 
                style={{ opacity: 0.5 }}
              />
              <h2>Template</h2>
              <p>Start applyng immediately our AI model on a ready-made house with furniture inside. <br></br><br></br>Enjoy creating new configurations on a set of different properties</p>
            </ProjectCard>
            <ProjectCard 
              style={{ backgroundColor: '#f0f0f0', color: '#888', cursor: 'not-allowed' }} 
              onClick={(e) => e.preventDefault()}
            >
              <img 
                src={`${process.env.PUBLIC_URL}/empty_create_image.jpg`} 
                alt="Empty" 
                style={{ opacity: 0.5 }}
              />
              <h2>Empty</h2>
              <p>Create a project from scratch with no walls at all and all your creativity on your side<br></br><br></br>You are in complete control of the structure of the property</p>
            </ProjectCard>
          </CardsContainer>
        );
      case 2:
        return (
          <StepTwo 
            projectData={projectData} 
            setProjectData={setProjectData} 
            nextStep={() => setStep(3)} 
          />
        );
      case 3:
        return (
          <div>
            <h2>Step 3 Content</h2>
            <StepThree></StepThree>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <CreateProjectContainer>
      <Header>
        <Title>Create Project</Title>
      </Header>
      {renderStepContent()}
      <StepIndicatorContainer>
        {step > 1 && (
          <Button onClick={() => setStep(step - 1)}>Back</Button>
        )}
        <StepIndicator>
          <Step isActive={step === 1}>1</Step>
          <Step isActive={step === 2}>2</Step>
          <Step isActive={step === 3}>3</Step>
        </StepIndicator>
        {step === 2 && (
          <Button 
            onClick={() => setStep(step + 1)} 
            disabled={isNextDisabled}
          >
            Next
          </Button>
        )}
      </StepIndicatorContainer>
    </CreateProjectContainer>
  );
};

export default CreateProject;
